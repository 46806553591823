.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 640px;
  height: 640px;
  -webkit-backdrop-filter: blur(0.1px);
  backdrop-filter: blur(0.1px);
  position: absolute;
  top: calc(50% - 320px);
  left: calc(50% - 320px);
  -webkit-transition: 1s;
  transition: 1s;
  z-index: 3;
  user-select: none;
  pointer-events: none;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 300px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 23px;
  font-weight: 300;
  user-select: none;
  pointer-events: none;
  opacity: 0;
}

.text--ie {
  opacity: 1;
}

.active {
  animation: OPACITY_FRAME .8s forwards;
}

@keyframes OPACITY_FRAME {
  from  {
    transform: translate(0px, 25px);
    filter: blur(10px);
    opacity: 0;
  }
  to {
    transform: translate(0px, 0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@media all and (max-width: 700px){
  .wrap {
    background: rgba(30, 50, 75, 0.61);
    width: 280px;
    height: auto;
    top: auto;
    bottom: 20%;
    left: calc(50% - 140px);
    padding: 22px 20px;
  }
  .text {
    height: auto;
    font-size: 14px;
  }
}

@media all and (max-height: 768px){
  .wrap {
    top: calc(50% - 320px + 66px);
  }
}

@media all and (max-height: 768px) and (max-width: 940px){
  .wrap {
    top: 0;
    height: 100%;
  }
}

@media all and (max-height: 768px) and (max-width: 700px){
  .wrap {
    top: auto;
    height: auto;
  }
}