.scrollbar {
  user-select: none;
  pointer-events: none;
  width: 440px;
  height: 440px;
  left: calc(50% - 220px);
  top: calc(50% - 220px);
  position: absolute;
  z-index: 3;
  background: url(/files/images/svg/circle.png) center no-repeat;
  background-size: 440px;
}

.drag {
  user-select: none;
  pointer-events: none;
  width: 440px;
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.line {
  user-select: none;
  pointer-events: none;
  background: url(/files/images/svg/line.svg) center left no-repeat;
  background-size: 87px 330px;
  width: 87px;
  height: 331px;
  -webkit-transform: rotate(-3.2deg) translate(-4px, -2px);
  transform: rotate(-3.2deg) translate(-4px, -2px);
}

@media all and (max-width: 940px){
  .scrollbar {
    width: 302px;
    height: 302px;
    left: calc(50% - 151px);
    top: calc(50% - 151px);
    background-size: cover;
  }
  .drag {
    width: 302px;
    height: 302px;
  }
  .line {
    background-size: 60px 227px;
    width: 60px;
    height: 227px;
    background: url(/files/images/svg/line-min.svg) center left no-repeat;
    transform: rotate(-3deg) translate(-2px, -2px);
  }
}

@media all and (max-height: 768px){
  .scrollbar {
    top: calc(50% - 220px + 66px);
  }
}