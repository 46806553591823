.nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  position: absolute;
  left: 80px;
  bottom: 25px;
  z-index: 5!important;
}

.item {
  font-size: 0;
  height: 20px;
  width: 32px;
  padding: 0;
  border: 0;
  opacity: 0.4;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  &::after {
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    top: 9px;
    left: 0;
  }
}

.active {
  opacity: 1;
}

@media all and (max-width: 700px){
  .nav {
    bottom: 40px;
    left: 20px;
  }
}